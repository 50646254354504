// splash.scss

// * {border: 1px solid red !important;}

@use "sass:color";

// Clearing floats
.cl-both {
  clear: both;
}
.cl-l {
  clear: left;
}
.cl-r {
  clear: right;
}

// Positioning
.pos-a {
  position: absolute;
}
.pos-r {
  position: relative;
}

// Display classes
.d-b {
  display: block;
}
.d-ib {
  display: inline-block;
}
.d-g {
  display: grid;
}
.d-ig {
  display: inline-grid;
}
.d-f {
  display: flex;
}
.d-t {
  display: table;
}
.d-tr {
  display: table-row;
}
.d-tc {
  display: table-cell;
}

// Width and height classes
.w-32 {
  width: 32%;
}
.w-33 {
  width: 33%;
}
.w-34 {
  width: 34%;
}
.w-50 {
  width: 50%;
}
.w-66 {
  width: 66%;
}
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}

.h-80px {
  height: 80px;
}
.h-20 {
  height: 20%;
}
.h-80 {
  height: 80%;
}
.h-100 {
  height: 100%;
}

// Float classes
.fl-l {
  float: left;
}
.fl-r {
  float: right;
}

// Text alignment
.ta-c {
  text-align: center;
}
.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}

// Line heights
.lh-0-7 {
  line-height: 0.7em;
}
.lh-1-0 {
  line-height: 1em;
}
.lh-1-1 {
  line-height: 1.1em;
}
.lh-1-2 {
  line-height: 1.2em;
}
.lh-1-3 {
  line-height: 1.3em;
}
.lh-1-4 {
  line-height: 1.4em;
}
.lh-1-5 {
  line-height: 1.5em;
}

// Padding
.pt-05 {
  padding-top: 0.5em;
}
.pt-1 {
  padding-top: 1em;
}
.pb-05 {
  padding-bottom: 0.5em;
}
.pb-1 {
  padding-bottom: 1em;
}
.pb-1-5 {
  padding-bottom: 1.5em;
}
.pl-0-15in {
  padding-left: 0.15in;
}
.pl-1 {
  padding-left: 1em;
}
.pl-2 {
  padding-left: 2em;
}
.pl-3 {
  padding-left: 3em;
}
.pr-0-15in {
  padding-right: 0.15in;
}
.pr-1 {
  padding-right: 1em;
}
.pr-2-5 {
  padding-right: 2.5em;
}
.p-0 {
  padding: 0;
}
.p-01in-0 {
  padding: 0.1in 0;
}
.p-0-01in {
  padding: 0 0.1in;
}
.p-01in-01in {
  padding: 0.1in 0.1in;
}
.p-01in-02in {
  padding: 0.1in 0.2in;
}
.p-015in-02in {
  padding: 0.15in 0.2in;
}
.p-0-015in-02in {
  padding: 0 0.15in 0.2in;
}
.p-02in-02in {
  padding: 0.2in 0.2in;
}
.p-0-1em {
  padding: 0 1em;
}

// Margin
.mt-a {
  margin-top: auto;
}
.mt-0 {
  margin-top: 0;
}
.mt-0-5 {
  margin-top: 0.5em;
}
.mt-0-75 {
  margin-top: 0.75em;
}
.mt-1 {
  margin-top: 1em;
}
.mt-1-5 {
  margin-top: 1.5em;
}
.mt-2 {
  margin-top: 2em;
}
.mr-a {
  margin-right: auto;
}
.mr-2 {
  margin-right: 2em;
}
.mb-a {
  margin-bottom: auto;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-0-25 {
  margin-bottom: 0.25em;
}
.mb-0-5 {
  margin-bottom: 0.5em;
}
.mb-0-75 {
  margin-bottom: 0.75em;
}
.mb-1 {
  margin-bottom: 1em;
}
.ml-a {
  margin-left: auto;
}
.ml-2 {
  margin-left: 2em;
}
.ml-5p {
  margin-left: 5%;
}
.m-c {
  margin-left: auto;
  margin-right: auto;
}
.m-0 {
  margin: 0;
}
.m-0-auto {
  margin: 0 auto;
}
.m-0-c {
  margin: 0 auto;
}
.m-0-2-auto {
  margin: 0.2in auto;
}
.m-05em-auto {
  margin: 0.5em auto;
}
.m-05em-05em {
  margin: 0.5em 0.5em;
}
.m-05em-075em {
  margin: 0.5em 0.75em;
}
.m-1em-auto {
  margin: 1em auto;
}
.m-1em-05em {
  margin: 1em 0.5em;
}
.m-1em-075em {
  margin: 1em 0.75em;
}

// Color and background
.c-highlight1 {
  color: red;
}
.c-highlight2 {
  color: rgb(100, 0, 5);
}
.bgc-ddd {
  background-color: #ddd;
}
.bgc-ccc {
  background-color: #ccc;
}
.bgc-000 {
  background-color: #000;
}

// Vertical align
.va-t {
  vertical-align: top;
}
.va-m {
  vertical-align: middle;
}
.of-a {
  overflow: auto;
}
.of-h {
  overflow: hidden;
}

// List styles
.lst-n {
  list-style-type: none;
}

// Font styles
.fs-i {
  font-style: italic;
}

// Responsive styles for print
@media print {
  body {
    background-color: white !important;
  }
  #page {
    position: absolute !important;
    left: 0;
    top: 0;
    margin: auto !important;
  }
}

// Typography
body {
  font-family: "Arial Narrow", sans-serif;
  -moz-text-size-adjust: auto;
  -webkit-text-size-adjust: auto;
  -ms-text-size-adjust: auto;

  & > #page {
    position: relative;
    width: 8.5in;
    height: 11in;
    overflow: auto;
    margin: 0.5in auto;
    background-color: #ccc;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Verdana, Geneva, sans-serif;
}

a {
  text-decoration: none;
  color: blue;

  &:active {
    color: color.adjust(blue, $lightness: -25%);
  }

  span {
    color: black;
  }
}

em {
  font-weight: bold;
  font-style: normal;
}

// Icon styles
.icon-container {
  padding: 0 1em;
}

.fa {
  font-size: 1em;
  width: 1em;
  height: 1em;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}

a:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

// Grid Layouts
.gtr-1-1 {
  grid-template-rows: 1fr 1fr;
}
.gtr-1-2 {
  grid-template-rows: 1fr 2fr;
}
.gtr-1-3 {
  grid-template-rows: 1fr 3fr;
}
.gtr-1-4 {
  grid-template-rows: 1fr 4fr;
}
.gtc-1-1 {
  grid-template-columns: 1fr 1fr;
}
.gtc-1-1-1 {
  grid-template-columns: 1fr 1fr 1fr;
}
.gtc-2-1 {
  grid-template-columns: 2fr 1fr;
}
.gtr-2-1 {
  grid-template-rows: 2fr 1fr;
}

// Flexbox utilities
.jc-fs {
  justify-content: flex-start;
}
.jc-fe {
  justify-content: flex-end;
}
.jc-c {
  justify-content: center;
}
.jc-sa {
  justify-content: space-around;
}
.jc-sb {
  justify-content: space-between;
}
.jc-se {
  justify-content: space-evenly;
}
.flxd-r {
  flex-direction: row;
}
.flxd-rr {
  flex-direction: row-reverse;
}
.flxd-c {
  flex-direction: column;
}
.flxd-cr {
  flex-direction: column-reverse;
}
.flxw-no {
  flex-wrap: nowrap;
}
.flxw-w {
  flex-wrap: wrap;
}
.flxw-wr {
  flex-wrap: wrap-reverse;
}

// Flexbox Alignment
.ai-fs {
  align-items: flex-start;
}
.ai-fe {
  align-items: flex-end;
}
.ai-c {
  align-items: center;
}
.ai-b {
  align-items: baseline;
}
.ai-s {
  align-items: stretch;
}

.as-fs {
  align-self: flex-start;
}
.as-fe {
  align-self: flex-end;
}
.as-c {
  align-self: center;
}
.as-b {
  align-self: baseline;
}
.as-s {
  align-self: stretch;
}

.ac-s {
  align-content: start;
}
.ac-e {
  align-content: end;
}
.ac-fs {
  align-content: flex-start;
}
.ac-fe {
  align-content: flex-end;
}
.ac-sa {
  align-content: space-around;
}
.ac-sb {
  align-content: space-between;
}
.ac-se {
  align-content: space-evenly;
}
.ac-str {
  align-content: stretch;
}
