// default.scss

@import url("https://fonts.googleapis.com/css2?family=OpenDyslexic:wght@400;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;600&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap");

html {
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: #dddddd;
}

*,
*:before,
*:after {
  font-size: 100%;
  font-family: "OpenDyslexic", sans-serif;
  // font-family: "Lexend", sans-serif;
  // font-family: "Roboto Slab", serif;
  box-sizing: inherit;
}

.clearfix {
  overflow: auto;
  zoom: 1;
}

body {
  height: 100%;
  // margin: 3em 1.5em;
}
