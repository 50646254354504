@import url("https://fonts.googleapis.com/css2?family=OpenDyslexic:wght@400;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;600&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap");

* {
  font-size: 100%;
  // font-weight: bold;
  font-family: "OpenDyslexic", sans-serif;
  // font-family: "Lexend", sans-serif;
  // font-family: "Roboto Slab", serif;
}

.movie-table {
  a,
  .entry {
    margin-left: 1em;
  }
  h4 {
    color: #449944;
  }
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }
  div.movie {
    margin-top: 1.5em;
  }
  .sr-only {
    position: absolute;
    top: -30em;
  }

  table.sortable td,
  table.sortable th {
    padding: 0.125em 0.25em;
    width: 8em;
  }
  table.sortable td.runtime,
  table.sortable th.runtime {
    width: 4em;
    text-align: right;
  }
  table.sortable td.links,
  table.sortable th.links {
    width: 12em;
  }
  table.sortable td.movie-title,
  table.sortable th.movie-title {
    width: 18em;
  }
  table.sortable th {
    font-weight: bold;
    border-bottom: thin solid #888;
    position: relative;
  }
  table.sortable th.no-sort {
    padding-top: 0.35em;
  }
  // table.sortable th:nth-child(5) {
  //   width: 10em;
  // }
  table.sortable th button {
    padding: 4px;
    margin: 1px;
    font-size: 100%;
    font-weight: bold;
    background: transparent;
    border: none;
    display: inline;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    text-align: left;
    outline: none;
    cursor: pointer;
  }
  table.sortable th button span {
    position: absolute;
    right: 4px;
  }
  table.sortable th[aria-sort="descending"] button span::after {
    content: "▼";
    color: currentcolor;
    font-size: 100%;
  }

  table.sortable th[aria-sort="ascending"] button span::after {
    content: "▲";
    color: currentcolor;
    font-size: 100%;
  }

  /* Remove the extra ▼ that appears during hover or focus */
  table.sortable th:not([aria-sort]) button:hover span::after,
  table.sortable th:not([aria-sort]) button:focus span::after {
    content: "";
  }

  table.show-unsorted-icon th:not([aria-sort]) button span::after {
    content: "♢";
    color: currentcolor;
    font-size: 100%;
    position: relative;
    top: -3px;
    left: -4px;
  }

  table.sortable {
    td.num {
      text-align: right;
    }
    thead tr,
    tbody tr:nth-child(even) {
      background-color: #eee;
    }
    tbody tr:hover {
      background-color: #cfcaca;
    }
  }

  /* Focus and hover styling */
  table.sortable th button:focus,
  table.sortable th button:hover {
    padding: 2px;
    border: 2px solid currentcolor;
    background-color: #e5f4ff;
  }

  table.sortable th button:focus span,
  table.sortable th button:hover span {
    right: 2px;
  }
}
